import { createSharedComposable } from "@vueuse/core";
import * as filestack from "filestack-js";
import { useFetch } from "nuxt/app";
import { filestackConvertToPdf, filestackUtils } from "~/utils/filestack";
import { useToast } from "./useToast";

const { addToastError } = useToast();

export const useFilestackSecurity = createSharedComposable(() => {
  const { data, refresh, error } = useFetch("/api/filestack");
  const security = computed(() => data.value?.security);

  if (error.value) {
    addToastError(
      {
        title: "Erreur de récupération des informations de sécurité pour Filestack",
      },
      error.value
    );
  }

  setInterval(refresh, 1000 * 60 * 60); // refresh the token every hour
  return { security, refresh };
});

export const useFilestackUtils = createSharedComposable(function () {
  const { security } = useFilestackSecurity();

  const isReady = computed(() => !!security.value);

  const downloadUrl = function (url?: string) {
    if (security.value) return filestackUtils(security.value).downloadUrl(url);
    else return "";
  };

  type ThumbnailParams = { height?: number; page?: number };

  const thumbnail = (url: string, params: ThumbnailParams = {}) => {
    if (security.value) return filestackUtils(security.value).thumbnail(url, params);
    else return "";
  };

  const thumbnailUrls = (url: string, params: ThumbnailParams = {}) => {
    if (security.value) return filestackUtils(security.value).thumbnailUrls(url, params);
    else return [];
  };

  return {
    downloadUrl,
    thumbnail,
    isReady,
    thumbnailUrls,
  };
});

export const useFilestackClient = createSharedComposable(function () {
  const config = useRuntimeConfig();

  const { security } = useFilestackSecurity();

  const client = computed(() => filestack.init(config.public.FILESTACK_KEY, { security: security.value }));

  const definePickerOptions = function (options: filestack.PickerOptions) {
    return options;
  };

  const defineUploadOptions = function (options: filestack.UploadOptions) {
    return options;
  };

  return {
    client,

    definePickerOptions,
    defineUploadOptions,
  };
});

export const useFilestackConvertToPdf = function () {
  const { security } = useFilestackSecurity();

  const convertToPdf = async function (handleOrUrl: string) {
    if (!security.value) throw new Error("Security is not set");
    return await filestackConvertToPdf(security.value).convertToPdf(handleOrUrl);
  };

  return { convertToPdf };
};
